import brokenCss from "./images/debian-specs-broken-css.png"

import {Link} from "../shared/components/Link.js";
import {Image} from "../shared/components/Image.js"
import {getFullLocation, getProjectByName} from "../projects/ProjectList.ts";

export function SpecificationsAreEvil() {
	return <>
		<p>Before specifications existed, life was so simple. We got up in the morning, gathered some friends and went off to hunt a mammoth for a week with spears. Then, one time, Andy brought a spear too heavy to throw, and the prey got away. That was the point the tribes decided to not let people do things any way they wanted; instead, there arose a need for control. Why is this relevant, you might ask? Because I just spent a small chunk of my life reading and implementing the specifications for various debian control files. And while it's far from the worst I've seen, it shows how unnecessarily complicated specifications can make our lives.</p>
		<p>Of course, without these conventions, we might never have progressed this far as a society. That doesn't mean there isn't a long way to go, however. For instance, getting all debian control files to use the same format, instead of having the copyright file be subtly different, would be a good first step in improving the situation.</p>
		<p>So, what are we looking at here? About <Link href="https://www.debian.org/doc/debian-policy/ch-controlfields.html">20 pages of specs</Link>, plus some more for the copyright format - that's not bad at all. It only took me about two days to go through them, but it wasn't a smooth ride. One of my main gripes is the inconsistencies between various control files. For instance:</p>
		<ul>
			<li>Only <code>debian/control</code> files are allowed to have comments</li>
			<li>Mixed comma- and whitespace-separated lists (even for the same fields)</li>
			<li>Missing or self-contradictory examples</li>
		</ul>
		<p>It also doesn't help that while I was working, they pushed a minor update to the specs - nothing significant changed, but the new update broke the mime types for the stylesheets, resulting in this beauty:</p>
		<Image decorative={false} src={brokenCss} alt="A screenshot showing broken styling on the specification page." title="The stylish new look" credit="https://www.debian.org/doc/debian-policy/ch-controlfields"></Image>
		<p>Now, I'm sure there is some good reason why <code>debian/control</code> files can have comments (legacy support, probably), but why is it explicitly forbidden for every other file type? The parser support is clearly present. List separators? The idea seems to be 'space-separated for humans, comma-separated for machines', which would be understandable, <i>except the entire point of the specification is to be both human- and machine-readable.</i> At that point, this distinction makes little sense.</p>
		<p>Though, I have to admit, nothing beats the <b>whitespace-separated multiline list</b> used for file lists in the <code>debian/copyright</code> format - that's just outright demonic. You see, there are two types of fields that can span multiple lines: <code>folded</code> and <code>multiline</code>. In a <code>folded</code> field, no whitespace is significant, making the list rather unworkable. <code>Multiline</code> fields have the opposite problem: in that field, all whitespaces are significant, including line separators and the mandatory leading space for continuation lines, so an <i>actual</i> whitespace-separated list would be half-full with empty entries. If I want it to makes sense, I have to diverge from the specification.</p>
		<p>Less important fields, such as vcs fields, don't even have a syntax specified, save for Git and Mercurial. There is no information on how PGP signatures are added to files either, nor are there many examples. I actually found various examples for control files that had errors - maybe it was intentional to make them more readable, I'm not sure. Not a great look for a specification.</p>
		<p>Of course, this is only the surface. Some fields, such as the dependency fields, link to a whole other specification. For testing, I had to resort to downloading various control files straight from the debian repositories. One of the packages I looked at was <code>tree</code> - surely, a so widely used project would do it properly? <small><i>I can be rather naive.</i></small></p>
		<p>I noticed some of their links were still using HTTP... not a big deal. What is a big deal is their Git repository giving 404 errors in their <code>.dsc</code> file... it's still pointing to a discontinued debian service they were supposed to migrate away from in 2017. <i>Sigh.</i></p>
		<p>Oh, did I mention that their homepage url is also invalid? That makes every single url in that file useless. And they must have been broken for a couple years, without anyone noticing... this is where manual reviews get you.</p>
		<p>If only they used <Link href={getFullLocation(getProjectByName("debian-control-linter"))}>debian-control-linter</Link> in their processes, this would never have happened. Though, if they have been using a good enough tool, I might never have bothered to write it. It seems these specifications are the necessary evil we live with to stimulate development. I hope this little trip gave you some idea about what it's like to work with specifications - it's been quite a journey.</p>
	</>
}

/*
 return <>
 <p>We all know (and love) the people who give their +1 to feature requests, talk about their ideas but never end up contributing to the project - they make up the majority of any sizable community in open-source. And there are the developers, the people who spend hours upon hours on the project for peanuts, and are expected to cater to the community at large. They are the people we all love - or do we?</p>
 <h3>Developers = Conflict</h3>
 <p>Having multiple people working towards the same goal together is a wonderful thing, however that rarely happens in practice. Usually, you get a bunch of people working on their part of the project, periodically posting progress updates or some future plans - which doesn't sound so bad, and it isn't, until they start conflicting each other. Maybe Dev A announced some plans, but never got back to them, and now Dev B cannot work on their new feature (but, of course, "I will do that once I finish this one small thing", goes Dev A). Then Dev C takes over that task and wants to do it differently, potentially screwing Dev B over in the process. And there comes the inevitable argument about whose idea was better, and now people are mad and nothing gets done.</p>
 <p>This is entirely different from corporate development. If your manager tells you to do it one way, you don't go around implementing it another way or you get fired. And if you think the expected solution is dumb, well, it's not your problem; you can just find another job if the company goes under. For open-source developers, on the other hand, there is no such hierarchy. Even if the project has people in charge of making these decisions, there is nothing stopping you from forking the project, doing it your way, and still contribute to the parent project afterwards. This kind of unfettered development access is one of the greatest strengths of open-source, and is also one of its core weaknesses.</p>
 <p>One thing to always remember is that all devs are there to help. Every single one of them (and even the married ones) want to make the final product better in some way, even if they don't agree with each other on how to best achieve that. They aren't working to create a competing product by forking the original, they don't want a fragmented community, but they also don't want something detrimental to get merged. And, naturally, after one pours so much effort into a project, they take personal pride in their achievements and contributions, along with the notion that they are better qualified to judge what does or doesn't belong in the program.</p>
 <h3>Schrödinger's merge request</h3>
 <p>No matter how experienced you are, you can still be wrong. And if two qualified people hold opposite opinions, usually at least one of them isn't entirely right. Sometimes there so no way of objectively telling which idea is better without adding them to the product, at which point there might not be a convenient way to revert it. (What if somebody's production code depends on this? Better give it 5 years of extended LTS support, just in case...) And, of course, by that time one of the competing devs is mad because it's not their solution that got merged. Even the arguments themselves can cause serious behind-the-scenes drama, damaging the mental health of developers on all sides (and even outsiders'), and dampens development.</p>
 <p>One way of avoiding this is to not merge anything controversial, which might salvage some of the devs' mental health, but easily leads to a dead project where nothing ever gets merged. It's like a spiced up version of Schrödinger's cat: we don't know what's the right choice until trying it, but not trying also kills the cat, and making the wrong guess about the cat's luck gets us arrested for animal abuse.</p>
 <p>To solve this issue, we need to do a better job of locating the root cause.</p>
 </>;

*/
