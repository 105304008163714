import "./BlogFormat.css"

export function BlogPost({children}) {
	return <div className="blogPost">
		{children}
	</div>
}

export function BlogTitle({date = undefined, children}) {
	return <div className="blogTitle">
		<h1>{children}</h1>
		{date ? <small>
			<time dateTime={date}>{new Date(date).toLocaleDateString()}</time>
		</small> : <></>}
	</div>
}

export function BlogBody({children}) {
	return <>{children}</>;
}