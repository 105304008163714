export function isHostedOnLocalhost(hostname: string = window.location.hostname): boolean {
	return hostname === "localhost";
}

export function isHostedOn(domain: string, hostname: string = window.location.hostname, allowSubdomain: boolean = true): boolean {
	if (hostname === domain) {
		return true;
	} else {
		return allowSubdomain && hostname.endsWith("." + domain);
	}
}

export function getActiveSubdomain(href: string = window.location.href): string {
	const parts = href.split(".");
	if (parts.length <= 2) {
		return "";
	}
	return parts.slice(0, parts.length - 2).join(".");
}

export function getDomain(base: string = "tibetiroka.com"): string {
	const sub = getActiveSubdomain();
	return sub === "" ? base : sub + "." + base;
}

export function getDomainWithProtocol(protocol: string = window.location.protocol, baseDomain: string = undefined): string {
	return protocol + "//" + (baseDomain ? getDomain(baseDomain) : getDomain());
}

export function isOfficial(href: string = window.location.href): boolean {
	if (href === "localhost" || href.startsWith("/")) {
		return true;
	}
	href = new URL(href).hostname;
	return isHostedOnLocalhost(href) || isHostedOn("tibetiroka.com", href);
}

if (!isOfficial()) {
	const domain = getDomainWithProtocol();
	const url = new URL(domain + window.location.pathname + window.location.search);
	window.location.replace(url);
}