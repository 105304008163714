import {isOfficial} from "./GlobalRedirect.ts";

document.addEventListener('click', e=> {
	if(e.target.matches("a *") || e.target.matches("a")){
		const target = e.target.closest("a");
		const link = target.href;
		if(link) {
			if(!isOfficial(link)) {
				target.target = "_blank";
			}
		}
	}
});