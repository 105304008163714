import standard from "./logo-standard.png";
import santaHat from "./logo-santa hat.png";
import laserEyes from "./logo-laser eyes.png";
import deepFried from "./logo-deep fried.jpg";

// caching the active icon
export const websiteLogo = chooseIcon();

// Setting the icon for favicon links
document.querySelectorAll("link").forEach(link => {
	if(link.rel === "icon" || link.rel === "apple-touch-icon") {
		link.href = websiteLogo;
	}
});

function chooseIcon() {
	const date = new Date(Date.now());
	if(date.getMonth() === 11 || (date.getMonth() === 0 && date.getDay() <= 7)) {
		return santaHat;
	} else if(date.getMonth() === 9 && date.getDay() === 31) {
		if(date.getMilliseconds() === 0) {
			return deepFried;
		}
		return laserEyes;
	} else {
		return standard;
	}
}