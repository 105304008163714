import {getFullLocation, getProjectByName} from "./ProjectList.ts";
import esBanner from "./endless-sky-0-10-3.png";
import esmanager from "./esmanager-plugins.png";
import debianControlLinter from "./debian-control-linter-marketplace.png"
import {Image, Link} from "../shared/CustomComponents.js";

function Projects() {
	return <>
		<h1>Projects overview</h1>
		<p>On this website I only list projects I'm actively contributing to. This helps reduce the amount of spam and random bug reports I receive. Currently, this is a fairly short list of two major projects: an open-source game called Endless Sky, and a custom instance manager I wrote for it called ESManager.</p>
		<h2>Endless Sky</h2>
		<p>Endless Sky is a free and open-source game available on <Link href="https://store.steampowered.com/app/404410/Endless_Sky/">Steam</Link> and <Link href="https://github.com/endless-sky/endless-sky/">GitHub</Link>.</p>
		<Image src={esBanner} alt="In-game screenshot showing a battle between several spaceships." title="The banner image of the 0.10.3 release" credit="https://github.com/endless-sky/endless-sky/releases/tag/v0.10.3"></Image>
		<p>For more information, check out the links above, the <Link href={getFullLocation(getProjectByName("Endless Sky"))}>project page</Link> or the <Link href="https://discord.gg/ZeuASSx">Endless Sky Community server</Link> on Discord.</p>
		<h2>ESManager</h2>
		<p>ESManager is a highly customizable instance manager for <Link href={getFullLocation(getProjectByName("Endless Sky"))}>Endless Sky</Link>. It has full Git and build system integration, can pull artifacts from releases and pull requests, use different plugins for every installation and keeps all of this up to date for you automatically.</p>
		<Image src={esmanager} alt="UI screenshot of the 'Plugins' menu of ESManager, showing a list of avaiable plugins." title="The 'Plugins' menu of ESManager" credit="https://github.com/tibetiroka/ESManager"/>
		<p>A more detailed description can be found on the <Link href={getFullLocation(getProjectByName("ESManager"))}>project page</Link> and in the <Link href="https://github.com/tibetiroka/ESManager">GitHub repository</Link>.</p>
		<h2>debian-control-linter</h2>
		<p>An actually useful <code>debian/copyright</code> linter, available as a command-line tool on <Link href="https://github.com/tibetiroka/debian-control-linter">GitHub</Link>, and also as a <Link href="https://github.com/marketplace/actions/debian-control-linter">GitHub Action</Link>.</p>
		<Image src={debianControlLinter} alt="A screenshot from the GitHub Marketplace page of the linter, showing usage info." title="The GitHub marketplace page of the linter" credit="https://github.com/marketplace/actions/debian-control-linter"></Image>
		<p>The backstory of the linter can be read on the <Link href={getFullLocation(getProjectByName("debian-control-linter"))}>project page</Link>.</p>
		<h2>Minor projects</h2>
		<p>I'm also a contributor to some minor projects, such as the official <Link href="https://github.com/endless-sky/all-content-plugin">All-Content Plugin</Link> for Endless Sky. These are however too small or too erratic to be worth mentioning on this site.</p>
		<p>In the past, I also developed a large number of plugins for <a href="https://getbukkit.org/download/spigot">Spigot</a>/<Link href="https://papermc.io/">Paper</Link> based <Link href="https://www.minecraft.net">Minecraft</Link> servers for private use. At some point, I even got into modding with <a href="https://fabricmc.net/">Fabric</a>, however it's been a couple years since I did anything significant in either of these spaces.</p>
	</>;
}

export default Projects;