import "./shared/SharedCode.js";
import 'bootstrap/dist/css/bootstrap.min.css';
//
import React from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
//
import Home from './Home.js';
import {Page404} from './shared/404.js'
import SharedLayout from "./SharedLayout.js";
import "./index.css"
import Projects from "./projects/Projects.js";
import {projects} from "./projects/ProjectList.ts";
import {BlogList, blogs} from "./blog/BlogList.tsx";
import About from "./About";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route exact path="/" Component={SharedLayout}>
					<Route index Component={Home}/>
					<Route exact path="/about" Component={About}/>
					<Route exact path="/projects">
						<Route index Component={Projects}/>
						{createProjectRoutes()}
					</Route>
					<Route exact path="/blog">
						<Route index Component={BlogList}/>
						{createBlogRoutes()}
					</Route>
					<Route path="*" Component={Page404}/>
				</Route>
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);

function createProjectRoutes() {
	return <>
		{projects.map(p => {
			return <Route exact path={p.url} key={p.name} Component={p.component}/>
		})}
	</>
}

function createBlogRoutes() {
	return <>
		{blogs.map(b => {
			return <Route exact path={b.url} key={b.id} Component={() => <BlogList posts={[b]}></BlogList>}/>
		})}
	</>
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);