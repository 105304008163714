import errors from "./debian-control-linter-errors.png"
import report from "./debian-bugtracker-es-copyright.png"
import {Image, Link} from "../shared/CustomComponents.js"
import {getProjectByName} from "./ProjectList.ts";

function DebianControlLinter() {
	return <>
		<h1>debian-control-linter</h1>
		<p>A powerful and flexible tool for validating your control files. It's one of the very, very few actually useful option for linting <code>debian/copyright</code> files.</p>
		<Image src={errors} alt="A screenshot showing various linter errors." title="Some errors generated from a copyright file"></Image>
		<p>Most tools you can find for control files are parsers or generators. Linters are scarce to the point of even the debian team going without them - they have some tools for various package control files, but copyright files only get manual reviews.</p>
		<p>Over at <Link href={"/projects/" + getProjectByName("Endless Sky").url}>Endless Sky</Link>, we already had a linter for the copyright file in our CI setup, but that didn't stop us from receiving a bug report from the debian team.</p>
		<Image src={report} alt="A screenshot from the debian bugtracker mentioning issues with the copyright file." title="The bug report received from the debian team" credit="https://bugs.debian.org/cgi-bin/bugreport.cgi?bug=1061241"></Image>
		<p>We had various PRs fixing copyright files, but one thing we couldn't find was an automated tool that would actually tell us what's wrong. So I wrote one. And oh boy, it did find issues... about a hundred hard errors <i>after</i> we meticulously went through the file and fixed everything we thought was wrong. Sure, most of those were due to the order of the copyright entries, which is what we were told about, but about 30 issues were just generic fuck-ups.</p>
		<p>Remember, this is after we went through the file, and after several years of being part of the debian repository. Some of the issues were quite ancient, and they were all missed by manual reviews. Automation is key.</p>
		<p>If you want to try it out, you can download the command-line version from <Link href="https://github.com/tibetiroka/debian-control-linter">GitHub</Link>, or you can integrate the <Link href="https://github.com/marketplace/actions/debian-control-linter">GitHub Action</Link> into your workflows directly.</p>
	</>
}

export default DebianControlLinter;