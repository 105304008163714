import {getProjectByName} from "./ProjectList.ts";
import esmanager from "./esmanager-plugins.png"
import {Image, Link} from "../shared/CustomComponents.js";

function ESManager() {
	return <>
		<h1>ESManager</h1>
		<p>ESManager is a highly customizable instance manager for <Link href={"/projects/"+getProjectByName("Endless Sky").url}>Endless Sky</Link>. It has full Git and build system integration, can pull artifacts from releases and pull requests, use different plugins for every installation and keeps all of this up to date for you.</p>
		<Image src={esmanager} alt="UI screenshot of the 'Plugins' menu of ESManager, showing a list of avaiable plugins." title="The 'Plugins' menu of ESManager" credit="https://github.com/tibetiroka/ESManager"/>
		<h2>Why ESManager</h2>
		<p>Endless Sky already has a semi-official instance manager called <a href="https://github.com/EndlessSkyCommunity/ESLauncher2">ESLauncher2</a>. So why did I go through the trouble of building a new one?<br/>
			Because it is largely unmaintained. Bugfixes still happen for major bugs, but even simple features like audio controls haven't been added, despite numerous requests over the years.
		</p>
		<p>The best solution would have been to put by back into it and implement all of ESManager's features in ESLauncher2, making it available to all users. But that would have involved an immense amount of work in a language and ecosystem I'm unfamiliar with, and the resulting code would have been nearly impossible to maintain due to the lack of some language features in Rust, which ESLauncher2 is written in. The absence of reflection would already mean a massive additional workload for configuration files, and the UI code would have to be orders of magnitudes as complex to cover all edge cases - none of which I was willing to write on a whim.</p>
		<p>There might be some libraries around to do the heavy lifting for me. I could have ripped out the UI libraries, added libgit2 and changed out whatever else needed replacing. However, at that point, we are looking at a full rewrite of ESLauncher2, and if I have to do a clean start, I might as well make it easier on myself and do it my way.</p>
		<p>ESManager is built using Java and JavaFX, which - with a few maven dependencies - already does the vast majority of the work. All I needed to do was to implement the business logic, which in itself already ended up being quite a lot - feature creep was definitely an issue during development. But here we are now, with a working application, so it couldn't have been too bad, right?</p>
		<p>Okay, there are some things I'm still not happy about. The packaging format for the native Windows and macOS builds could use some more work, and there is a weird bug on macOS I can't fix - whatever. The project works beautifully on most GNU/Linux systems, and that's already more than what I expected when I started.</p>
		<p>For more information and the full list of features, check out the <Link href="https://github.com/tibetiroka/ESManager">GitHub repository</Link>.</p>
	</>
}

export default ESManager;