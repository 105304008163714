import banner from "./endless-sky-0-10-3.png"
import wolfRayet from "./endless-sky-0-10-0.png"
import {Image, Link} from "../shared/CustomComponents.js"

function EndlessSky() {
	return <>
		<h1>Endless Sky</h1>
		<p>Endless Sky is a free and open-source game available on <Link href="https://store.steampowered.com/app/404410/Endless_Sky/">Steam</Link> and <Link href="https://github.com/endless-sky/endless-sky/">GitHub</Link>. It was originally written by Michael Zahniser, and is now fully developed and maintained by the community.</p>
		<Image src={banner} alt="In-game screenshot showing a battle between several spaceships." title="The banner image of the 0.10.3 release" credit="https://github.com/endless-sky/endless-sky/releases/tag/v0.10.3"></Image>
		<p>In this game you can battle pirates, help aliens or transport cabbage - whatever your heart desires. We have just released a new update with the beginning of a new storyline and tons of other content, be sure to check it out!</p>
		<Image src={wolfRayet} alt="In-game screenshot showing a Wolf-Rayet star with asteroids and an alien starship." title="A Wolf-Rayet star in-game" credit="https://github.com/endless-sky/endless-sky/releases/tag/v0.10.0"/>
		<p>I'm actively contributing code, content, small fixes and numerous reviews to this wonderful game. <Link href="https://github.com/endless-sky/endless-sky#contributing">You can help too!</Link></p>
	</>
}

export default EndlessSky;