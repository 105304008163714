import './About.css';
import {Image} from "./shared/CustomComponents";

function About() {
	const catSrc = "https://cdn.pixabay.com/photo/2016/12/30/17/27/cat-1941089_960_720.jpg";
	return (
		<div className="about">
			<h2>Hello there!</h2>
			<p>This is my personal website where I do random stuff from promoting open-source projects to hosting random web apps. I might even start a blog one day, or put my CV up here - anything can happen.</p>
			<p>Anyway, here's a cute cat picture:</p>
			<Image src={catSrc} alt="A cute cat picture" credit="https://pixabay.com/photos/cat-kitten-cute-sleeping-asleep-1941089/" decorative={false}></Image>
			<p>I'm a second year CS university student residing in Hungary. I like cats, playing games and am an active open-source contributor. I often spend my free time reading books or watching sci-fi series.</p>
		</div>
	);
}

export default About;