import {isOfficial} from "../GlobalRedirect.ts";

export function wrapLinkIfNecessary(link, component) {
	if(isOfficial(link)) {
		return component;
	}
	return <a href={link} className="custom-link-shared">{component}</a>;
}

export function wrapFigure(title, component, className = "") {
	return <figure className={className}>
		{component}
		{title ? <figcaption className="text-center text-secondary">{title}</figcaption> : ""}
	</figure>;
}