import './404.css';
import icon404 from "./404.png";
import {getDomainWithProtocol} from "./GlobalRedirect.ts";
import {Image} from "./CustomComponents";

export function Page404() {
	// 404.svg, 404.png based on image by freepik
	return (
		<div className="Page404">
			<Image src={icon404} alt="404" credit={getDomainWithProtocol()} decorative={false}></Image>
		</div>
	);
}