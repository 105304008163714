import {Outlet} from "react-router-dom";
import React from "react";
import {Container, Image, Nav, Navbar, NavDropdown, Table} from "react-bootstrap";
import "./SharedLayout.css"
import {websiteLogo} from "./shared/SetLogo.js";
import {projects} from "./projects/ProjectList.ts";

function SharedLayout() {
	const needsCredits = window.location.pathname === "/about";
	return (<div className="sharedLayout">
		<div className="mainContentDiv">
			<header>
				<nav className="navbar navbar-expand-lg">
					<Navbar expand="lg">
						<Container className="fs-4">
							<Navbar.Brand href="/"><Image className="logo"
														  alt="tibetiroka.com logo"
														  src={websiteLogo}/></Navbar.Brand>
							<Navbar.Toggle aria-controls="basic-navbar-nav"/>
							<Navbar.Collapse id="basic-navbar-nav">
								<Nav className="me-auto">
									<Nav.Link href="/">Home</Nav.Link>
									<Nav.Link href="/about">About</Nav.Link>
									<NavDropdown title="Projects" id="basic-nav-dropdown">
										{createProjectEntries()}
										<NavDropdown.Divider/>
										<NavDropdown.Item href="/projects">All projects</NavDropdown.Item>
									</NavDropdown>
								</Nav>
							</Navbar.Collapse>
						</Container>
					</Navbar>
				</nav>
			</header>
			<main>
				<article className="fs-5">
					<Outlet/>
				</article>
			</main>
		</div>
		<footer>
			<div className="row justify-content-center">
				<div className="col-auto">
					<Table className="table-borderless">
						<thead>
						<tr>
							{needsCredits ? <th>Credits</th> : ""}
							<th>Contact</th>
							<th>GitHub</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							{needsCredits ? <td>Some images are based on content by freepik</td> : ""}
							<td><a href="mailto:tibetiroka@tibetiroka.com">tibetiroka@tibetiroka.com</a></td>
							<td><a href="https://github.com/tibetiroka">github.com/tibetiroka</a></td>
						</tr>
						</tbody>
					</Table>
				</div>
			</div>
		</footer>
	</div>);
}

function createProjectEntries() {
	return <>
		{projects.map(p => {
			return <NavDropdown.Item href={"/projects/" + p.url} key={p.name}>{p.name}</NavDropdown.Item>;
		})}
	</>;
}

export default SharedLayout;