import {isOfficial} from "../GlobalRedirect.ts";

/**
 * Creates a new link that optionally displays the name of the site it points to.
 * @param {{children: *}} props The properties passed to the link element
 * @return The link element, potentially followed by descriptive text
 */
export function Link(props) {
	const href = props.href;
	const link = <a {...props}>{props.children}</a>;
	if(isOfficial(href) || childrenContainHostname(props, href)) {
		return link;
	} else {
		return <>{link}{<small className="link-hostname-display text-secondary">[{new URL(href).hostname}]</small>}</>
	}
}

/**
 * Searches an element's children to see if a hostname appears inside it.
 * @param href The link whose hostname is searched in the children of the element
 * @param {Object} props The properties of the element
 * @param {String | Array} props.children The children of the element, containing either a string or an array of (grand)children
 * @return True if the children contain the hostname, or a known alternative
 */
function childrenContainHostname({children}, href) {
	const hostParts =  new URL(href).hostname.split(".");
	const host = hostParts[hostParts.length - 2].toLowerCase();
	const popularHostMappings = {"steampowered": ["steam"], "steamcommunity": ["steam"], "discord": ["server"], "getbukkit": ["bukkit", "spigot"], "papermc": ["paper"]};
	//
	const searchValues = popularHostMappings[host] ? [host].concat(popularHostMappings[host]) : [host];
	function containsHostname(children) {
		if(typeof children === 'string' || children instanceof String) {
			const lower = children.toLowerCase();
			const strings = [lower, lower.replace(/\s+/, "")];
			return strings.some(text=> searchValues.some(search => text.includes(search)));
		} else {
			return children.some(child => containsHostname(child));
		}
	}
	return containsHostname(children);
}

export {Link as A};