import EndlessSky from "./EndlessSky.js";
import ESManager from "./ESManager.js";
import DebianControlLinter from "./DebianControlLinter.js"
import {FunctionComponent} from "react";

class Project {
	name: string;
	url: string;
	component: FunctionComponent;

	constructor(name: string, url: string, component: FunctionComponent) {
		this.name = name;
		this.url = url;
		this.component = component;
	}
}

export function getFullLocation(project: Project): string {
	return "/projects/" + project.url;
}

export function getProjectByName(name: String): Project {
	return projects.find(p => p.name === name);
}

export const projects: Project[] = [new Project("Endless Sky", "endless-sky", EndlessSky), new Project("ESManager", "esmanager", ESManager), new Project("debian-control-linter", "debian-control-linter", DebianControlLinter)];