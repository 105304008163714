import './Home.css';
import {BlogList} from "./blog/BlogList.tsx"

function Home() {
	return (
		<div className="home">
			<BlogList/>
		</div>
	);
}

export default Home;