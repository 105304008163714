import {Component, ReactElement, ReactNode} from "react";
import "./BlogFormat.js"
import {BlogBody, BlogPost, BlogTitle} from "./BlogFormat";
import {SpecificationsAreEvil} from "./Blogs.js";
// @ts-ignore
import {query} from "../shared/Api.ts";

export class Blog {
	id: number;
	title: ReactNode;
	body: Function;
	url: string;

	constructor(id: number, title: ReactNode,  url: string, body: Function) {
		this.id = id;
		this.title = title;
		this.url = url;
		this.body = body;
	}

	createComponent(post: { id: number, date: string }): ReactElement {
		// @ts-ignore
		return <BlogPost key={this.id}><BlogTitle date={post ? post.date : undefined}>{this.title}</BlogTitle><BlogBody>{this.body()}</BlogBody></BlogPost>
	}
}

export const blogs: Blog[] = [new Blog(5, "Specifications are evil", "specifications-are-evil", SpecificationsAreEvil)];

export class BlogList extends Component {
	posts: Blog[];

	constructor(props: { posts: Blog[] }) {
		super(props);
		this.posts = props.posts ?? blogs;
		this.state = {posts: JSON.parse(localStorage.getItem("posts") ?? "null")}
	}

	componentDidMount() {
		// @ts-ignore
		if(!this.state.posts || this.state.posts.find(p=>p.id === this.posts[0].id) === undefined) {
			query("blog/posts/list").then(({results}) => {
				this.setState({"posts": results});
				localStorage.setItem("posts", JSON.stringify(results));
			}).catch(console.error);
		}
	}

	render(): ReactElement {
		// @ts-ignore
		return <>{this.posts.map(b => b.createComponent(this.state.posts ? this.state.posts.find((p: Blog) => p.id === b.id) : undefined))}</>;
	}
}